// Ripped from `rails/ujs`

import * as csrf from "./csrf";
import { stopEverything } from "./event";

const isCrossDomain = function(url) {
  const originAnchor = document.createElement("a");
  originAnchor.href = location.href;
  const urlAnchor = document.createElement("a");
  try {
    urlAnchor.href = url;
    // If URL protocol is false or is a string containing a single colon
    // *and* host are false, assume it is not a cross-domain request
    // (should only be the case for IE7 and IE compatibility mode).
    // Otherwise, evaluate protocol and host of the URL against the origin
    // protocol and host.
    return !(
      ((!urlAnchor.protocol || urlAnchor.protocol === ":") &&
        !urlAnchor.host) ||
      originAnchor.protocol + "//" + originAnchor.host ===
        urlAnchor.protocol + "//" + urlAnchor.host
    );
  } catch (e) {
    // If there is an error parsing the URL, assume it is crossDomain.
    console.warning(
      "Making a cross domain ajax request which maybe we should not be doing"
    );
    return true;
  }
};

// Handles "data-method" on links such as:
// <a href="/users/5" data-method="delete" rel="nofollow" data-confirm="Are you sure?">Delete</a>
const handleMethod = function(e) {
  const link = this;
  const method = link.getAttribute("data-method");
  if (!method) {
    return;
  }

  const href = link.href;
  const csrfToken = csrf.csrfToken();
  const csrfParam = csrf.csrfParam();
  const form = document.createElement("form");
  let formContent = `<input name='_method' value='${method}' type='hidden' />`;

  if (csrfParam && csrfToken && !isCrossDomain(href)) {
    formContent += `<input name='${csrfParam}' value='${csrfToken}' type='hidden' />`;
  }

  // Must trigger submit by click on a button, else "submit" event handler won't work!
  // https://developer.mozilla.org/en-US/docs/Web/API/HTMLFormElement/submit
  formContent += '<input type="submit" />';

  form.method = "post";
  form.action = href;
  form.target = link.target;
  form.innerHTML = formContent;
  form.style.display = "none";

  document.body.appendChild(form);
  form.querySelector('[type="submit"]').click();

  stopEverything(e);
};

export { handleMethod };
