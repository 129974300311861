import jQuery from "jquery";
import { documentReady } from "../document-ready";
import { handleConfirm } from "./confirm";
import { handleMethod } from "./method";
import { delegate } from "./event";

const linkLocator =
  "a[data-confirm], a[data-method], a[data-remote]:not([disabled])";

documentReady(() => {
  delegate(jQuery("body").get(0), linkLocator, "click", handleConfirm);
  delegate(jQuery("body").get(0), linkLocator, "click", handleMethod);
});
