import { Spinner } from "spin.js";
import $ from "jquery";

const DEFAULT_OPTIONS = {
  lines: 13,
  length: 30,
  width: 14,
  radius: 40,
  corners: 1,
  rotate: 0,
  direction: 1,
  speed: 1,
  trail: 60,
  shadow: false,
  hwaccel: false,
  className: "spinner",
  zIndex: 2e9,
  top: "200px",
  color: "#f80", // #rgb or #rrggbb
  element: "spinner",
};

export class LTSpinner {
  constructor(opts) {
    this.opts = { ...DEFAULT_OPTIONS, ...opts };
    this.spinner = new Spinner(this.opts);
  }

  spin() {
    const left = $(".container").outerWidth() / 2 - 100;
    this.opts.left = left;
    this.spinner.spin(document.getElementById(this.opts.element || "spinner"));
  }
}
